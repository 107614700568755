import { render, staticRenderFns } from "./receipt-bundle-items.vue?vue&type=template&id=053696da&scoped=true"
import script from "./receipt-bundle-items.vue?vue&type=script&lang=ts"
export * from "./receipt-bundle-items.vue?vue&type=script&lang=ts"
import style0 from "./receipt-bundle-items.vue?vue&type=style&index=0&id=053696da&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "053696da",
  null
  
)

export default component.exports