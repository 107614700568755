import { Locale } from '../index.d';

const locales: Array<Locale> = [
  { code: 'de-DE', iso: 'de-DE', file: 'de-DE.ts', name: 'German', name_native: 'Deutsch' },
  { code: 'en-AU', iso: 'en-AU', file: 'en-AU.ts', name: 'English (AU)', name_native: 'English (AU)' },
  { code: 'en-GB', iso: 'en-GB', file: 'en-GB.ts', name: 'English (UK)', name_native: 'English (UK)' },
  { code: 'en-US', iso: 'en-US', file: 'en-US.ts', name: 'English (US)', name_native: 'English (US)' },
  { code: 'es-ES', iso: 'es-ES', file: 'es-ES.ts', name: 'Spanish', name_native: 'Español' },
  { code: 'fr-FR', iso: 'fr-FR', file: 'fr-FR.ts', name: 'French', name_native: 'Français' },
  { code: 'it-IT', iso: 'it-IT', file: 'it-IT.ts', name: 'Italian', name_native: 'Italiano' },
  { code: 'nl-NL', iso: 'nl-NL', file: 'nl-NL.ts', name: 'Dutch', name_native: 'Nederlands' },
  { code: 'pl-PL', iso: 'pl-PL', file: 'pl-PL.ts', name: 'Polish', name_native: 'Polski' },
  { code: 'pt-PT', iso: 'pt-PT', file: 'pt-PT.ts', name: 'Portuguese', name_native: 'Português' },
]

export default locales;
