import { datadogRum } from '@datadog/browser-rum';
import { PAYMENT_PROVIDERS, PAYMENT_PROVIDERS_IDS, PaymentProvider } from '@white-label-configuration/constants'
import { getAppVariable } from '@white-label-helper/get-app-variable';
import { isBookingPortal } from '@white-label-helper/is-booking-portal';

type PaymentConfig = {
  id: number;
  is_active: boolean;
  payment_provider_id: 1 | 2 | 3;
  merchant_id: string;
  config: {
    statement_descriptor: string;
  }
}

/**
 * Get the active payment provider from the partner config
 *
 * DEVELOPER NOTE:
 *
 * This function will attempt to get the active payment provider from the partner channel config.
 *
 * If no active payment provider is found, an error will be thrown.
 * If a single payment provider is found then it will be returned.
 * if multiple payment providers are found, the last one will be returned.
 *
 * _The last one is chosen because it is presumably the latest one to of been created. If this is
 * not the case then the problem is with the partner config data and not this function._
 *
 * For BookingPortal partners, this function will return null.
 *
 * @returns {PaymentProvider} The active payment provider
 */
export function getAppPaymentProvider(): PaymentProvider {
  if(isBookingPortal) return null
  const paymentProviders = getAppVariable<Array<PaymentConfig>>('payments');

  // filter for the active payment provider
  // DEVELOPER NOTE: this may return multiple payment providers due to the way the payment providers are stored in the partner config
  const filteredPaymentProviders: Array<PaymentConfig> = paymentProviders.filter((paymentProvider: PaymentConfig) => paymentProvider.is_active);

  if (filteredPaymentProviders.length === 0) {
    const error = new Error('No active payment providers found in the partner config');
    datadogRum.addError(error)
    throw error
  }

  // - return the last one as it is the most recently added if there are multiple active payment providers or,
  // - return the only (first) active payment provider if there is only one active payment provider
  const paymentProviderConfig = filteredPaymentProviders[filteredPaymentProviders.length > 1 ? filteredPaymentProviders.length - 1 : 0];
  const paymentProviderIdsEnvironmental = PAYMENT_PROVIDERS_IDS[process.env.NUXT_ENV_ENVIRONMENT ?? 'development']

  // find the payment provider tuple in the supported payment providers
  const paymentProviderTupleOrUndefined = Object.entries(paymentProviderIdsEnvironmental).find(([key]) => key === `${paymentProviderConfig.payment_provider_id}`);

  if (paymentProviderTupleOrUndefined === undefined) {
    const error = new Error(`Payment provider with id ${paymentProviderConfig.payment_provider_id} not found among the supported payment providers`);
    datadogRum.addError(error)
    throw error
  }

  return PAYMENT_PROVIDERS[paymentProviderTupleOrUndefined[1]];
}
