
// Types
import type { BraintreeConfig } from '@white-label-types/parking-checkout';
import type { Partners } from '@white-label-types/partners';

// Packages
import { defineComponent, PropType } from 'vue';

// Helpers
import { getAppVariable } from '@white-label-helper/get-app-variable';
import { getAppPaymentMerchantId } from '@white-label-helper/get-app-payment-merchant-id';

// Constants
import { PaymentMethods} from "@white-label-configuration/constants";

/**
 * {@link: https://uicomponent.test.payments.maginfrastructure.com/docs/braintree.html|You can find the document on how
 * this works here}
 */

type BraintreePaymentMethod = PaymentMethods.BraintreeHostedFields | PaymentMethods.BraintreePaypal;

export default defineComponent({
  props: {
    amount: {
      type: Number,
      required: true,
    },

    paymentsConfig: {
      type: Object as PropType<BraintreeConfig>,
      required: true,
    },

    paymentProviders: {
      type: Array as PropType<BraintreePaymentMethod[]>,
      required: true,
    },
  },

  data() {
    return {
      paymentMethod: '' as BraintreePaymentMethod,
    };
  },

  computed: {
    enabledPaymentMethods(): string {
      return JSON.stringify(this.paymentProviders.map((item) => item.replace('braintree-', '')));
    },

    currency(): Partners['default_currency'] {
      return getAppVariable('default_currency');
    },

    isThreeDSecureEnabled(): boolean {
      return !process.env.NUXT_ENV_BRAINTREE_3DS_DISABLED;
    },
  },

  mounted() {
    this.$nextTick(() => {
      const hostedFields = (this.$refs.payment as HTMLElement).querySelector('braintree-hosted-fields');
      const paypal = (this.$refs.payment as HTMLElement).querySelector('braintree-paypal');

      if (hostedFields) {
        this.setPaymentMethod(PaymentMethods.BraintreeHostedFields);
        hostedFields.addEventListener('click', () => {
          this.setPaymentMethod(PaymentMethods.BraintreeHostedFields);
        });
      }

      if (paypal) {
        if (!hostedFields) {
          this.setPaymentMethod(PaymentMethods.BraintreePaypal);
        }
        paypal.addEventListener('click', () => {
          this.setPaymentMethod(PaymentMethods.BraintreePaypal);
        });
      }
    });
  },

  methods: {
    setPaymentMethod(payload: BraintreePaymentMethod): void {
      this.paymentMethod = payload;
      this.$emit('payment-method-change', payload);
    },

    handlePayload(event: any): void {
      this.$emit('payment-completed', {
        payment_method_id: event.detail.nonce,
        merchant_id: getAppPaymentMerchantId(),
      });
    },

    handleError(): void {
      this.$emit('error');
    },

    submitForm(): void {
      const button = document.querySelector('.braintree-ui__button');
      if (button instanceof HTMLButtonElement) {
        button.click();
      }
    },
  },
});
