/**
 * Converts an RGBA colour to a HEX colour string
 *
 * @param orig
 * @param skipAlpha
 * @returns
 */
export function rgbaToHex(orig: string, skipAlpha: boolean = false): string {
  const rgba = orig.split(',');

  let hex = `${
    ((rgba[0] as unknown as number) | (1 << 8)).toString(16).slice(1)
  }${
    ((rgba[1] as unknown as number) | (1 << 8)).toString(16).slice(1)
  }${
    ((rgba[2] as unknown as number) | (1 << 8)).toString(16).slice(1)
  }`;

  if (skipAlpha) {
    return `#${hex}`;
  }

  const alpha = ((rgba?.[3]) || '').trim();

  let a;

  if (alpha !== '') a = alpha;
  else a = 0o1;

  a = (((a as unknown as number) * 255) | (1 << 8)).toString(16).slice(1);
  hex = `#${hex + a}`;

  return hex;
}
