
// Packages
import Vue, { defineComponent } from 'vue';

// Constants
import {
  HTTP_ERROR_TYPES,
  NAMED_ROUTES,
  CookiesNames,
  BOOKING_STATUSES,
} from '@white-label-configuration/constants';

// Helpers
import { required, notNumber } from '@white-label-helper/vuelidate';
import { getAppVariable } from '@white-label-helper/get-app-variable';
import { gaDataLayer } from '@white-label-helper/ga-data-layer';
import { checkIsLessOneHour } from '@white-label-helper/time-helpers';
import { updateBookingDetails } from '@white-label-helper/api-manage-booking';

// Store
import { mapActions, mapState } from 'pinia'
import { useManageBookingStore } from '@white-label-store/manage-booking';

// Types
import type { User } from '@white-label-types/parking-shared';
import type {
  FormSetup,
  UpdateDetailsValidationMessages,
  BookingDetails,
} from '@white-label-types/user-details';
import type { Partners } from '@white-label-types/partners-api';
import Button from '../button/button.vue';
import IconWrapper from '../icon-wrapper/icon-wrapper.vue';
import FormUpdateDetails from '../form-update-details/form-update-details.vue';
import BackButton from '../back-button/back-button.vue';
import UpdateDetailsPreloader from '../update-details-preloader/update-details-preloader.vue';
import UpdateDetailsTicket from '../update-details-ticket/update-details-ticket.vue';
import ReceiptOrderItems from '../receipt-order-items/receipt-order-items.vue';

export default defineComponent({
  name: 'UpdateDetails',
  components: {
    BackButton: BackButton,
    FormUpdateDetails,
    IconWrapper,
    IconAlert: () => import('@white-label-icon/icon-alert'),
    SubmitButton: Button,
    UpdateDetailsPreloader,
    UpdateDetailsTicket,
    OrderItems: ReceiptOrderItems,
  },

  data() {
    return {
      formSetup: {} as FormSetup<User, UpdateDetailsValidationMessages>,
      formDataHasChanged: false,
      disabled: false,
      loading: false,
      tryAgainCount: 0,
      modifications: {
        hideAdditionalInfo: true,
        accessInfoFromEntranceInfo: true,
      }
    };
  },

  computed: {
    ...mapState(useManageBookingStore,
      [
        'readUserInfo',
        'readGroups',
        'readInventoryName',
        'readAirportName',
        'readStringEntryTime',
        'readStringExitTime',
        'readOrderId',
        'readEntryExitInfo',
        'readLanguageISOCode',
        'readAvailableAdditinalFields',
        'readInventoryItem',
        'readManageBookingItems',
        'readBundle',
      ]
    ),
    userEmail(): ReturnType<typeof readUserInfo> {
      return this.readUserInfo;
    },

    customerGroups(): ReturnType<typeof readGroups> {
      return this.readGroups;
    },

    getAvailableAdditinalFields(): ReturnType<typeof readAvailableAdditinalFields> {
      return this.readAvailableAdditinalFields
    },

    stringEntryTime(): ReturnType<typeof readStringEntryTime> {
      return this.readStringEntryTime;
    },

    getEntryExitInfo(): ReturnType<typeof readEntryExitInfo> {
      return this.readEntryExitInfo;
    },

    getInventoryName(): ReturnType<typeof readInventoryName> {
      return this.readInventoryName;
    },

    orderID(): ReturnType<typeof readOrderId> {
      return this.readOrderId;
    },

    bookingDetails(): BookingDetails {
      const airport = this.readAirportName;
      const bookingDetails: BookingDetails = {
        stringEntryTime: this.stringEntryTime,
        stringExitTime: this.readStringExitTime,
        groups: this.readGroups,
        inventory_item: this.readInventoryItem,
      };

      if (typeof this.getInventoryName === 'string') {
        bookingDetails.inventoryName = this.getInventoryName;
      }

      if (typeof airport === 'string') {
        bookingDetails.airportName = airport;
      }

      return bookingDetails;
    },

    orderItems(): ReturnType<typeof readManageBookingItems> {
      return this.readManageBookingItems;
    },

    isAnyItemInNonUpdatableState(): boolean {
      return this.orderItems.some(i => {
        const status = i.status.toUpperCase();
        return status === BOOKING_STATUSES.CANCELLED || status === BOOKING_STATUSES.IN_PROGRESS || status === BOOKING_STATUSES.PAST_BOOKING;
      });
    },

    isBundleItem(): boolean {
      return this.$launchDarkly.variation('ECOM-2801_BUNDLES-PRE-PURCHASE')
        && !!this.readBundle
        && !!this.readBundle.items.length;
    },
  },

  mounted() {
    this.getBooking();
    this.loadLanguageFile();
  },

  methods: {
    ...mapActions(useManageBookingStore,
      [
        'dispatchState',
        'commitCancelBooking',
      ]
    ),
    /**
     * If multi-language is enabled and the iso code is a string use that to load the correct translation file
     */
    loadLanguageFile() {
      const isoLanguageCode = this.readLanguageISOCode;

      // TODO (Ecom) - Remove condition when feature flag 'ECOM-1233' is no longer needed
      if (
        this.$launchDarkly.variation('ECOM_1233_WL_MULTI_LANGUAGE') === true
      ) {
        if (typeof isoLanguageCode === 'string') {
          this.$i18n.setLocale(isoLanguageCode);
        }
      }
    },

    goToManageBooking() {
      this.$router.push(NAMED_ROUTES.manage_booking.default);
    },

    onClickBackToBooking() {
      if (this.formDataHasChanged) {
        this.$openModal('GlobalModalError', {
          header: this.$t('shared.modals.unsavedChanges.header') as string,
          body: this.$t('shared.modals.unsavedChanges.body') as string,
          buttons: [
            {
              btnText: this.$t('shared.modals.unsavedChanges.back') as string,
              callback: () =>
                this.$router.push(NAMED_ROUTES.manage_booking.default),
            },
            {
              btnText: this.$t(
                'shared.modals.unsavedChanges.continue'
              ) as string,
              callback: () => null,
            },
          ],
          isInfo: true,
        }).then((callback) => {
          if (callback && typeof callback === 'function') {
            callback();
          }
        });
      } else {
        this.$router.push(NAMED_ROUTES.manage_booking.default);
      }
    },

    initPage() {
      const userInfo = this.readUserInfo;
      const validations = {
        first_name: {
          required,
          notNumber,
        },

        last_name: {
          required,
          notNumber,
        },
      };

      const validationMessages = {
      };

      this.formSetup = {
        // @ts-ignore - Store type incompatible with User type
        formData: userInfo,
        validations,
        validationMessages,
      };

      const airportCode: Partners['poi']['code'] = getAppVariable('poi.code');
      const partnerName: Partners['partner_name'] =
        getAppVariable('partner_name');
      const siteLanguage: Partners['default_language'] =
        getAppVariable('default_language');

      this.$gtm.push(
        gaDataLayer({
          airportCode,
          partnerName,
          siteLanguage,
          loginStatus: true,
          airportTerminalId: this.getEntryExitInfo.terminal,
          parkingDepartureDate: this.getEntryExitInfo.entryDate,
          parkingDepartureTime: this.getEntryExitInfo.entryTime?.name,
          parkingReturnDate: this.getEntryExitInfo.exitDate,
          orderReference: this.orderID,
          carParkName: this.getInventoryName,
        })
      );
    },

    tryAgain() {
      this.$global_enableContentPreloader();
      this.getBooking();
    },

    getBooking() {
      const manageBookingToken = this.$cookies.get(CookiesNames.manage);

      if (manageBookingToken) {
        this.dispatchState(manageBookingToken.token)
          .then(() => {
            this.initPage();
            this.$global_disableContentPreloader();
            this.loadLanguageFile();
          })
          .catch(() => {
            this.$openModal('GlobalModalError', {
              header: this.$t(
                'shared.modals.errors.technicalIssue.header'
              ) as string,
              body: this.$t(
                'shared.modals.errors.technicalIssue.body'
              ) as string,
              btnText: this.$t('shared.buttons.tryAgain') as string,
              btnType: 'custom',
            }).then(this.tryAgain);
          });
      }
    },

    formDataChanged(eventPayload: {
      formDataChanged: boolean;
      hasErrors: boolean;
    }): void {
      this.formDataHasChanged = eventPayload.formDataChanged;
      this.disabled = !eventPayload.formDataChanged || eventPayload.hasErrors || this.isAnyItemInNonUpdatableState;
    },

    showBookingAlreadyCancelledModal(): void {
      this.commitCancelBooking();
      this.$openModal('GlobalModalError', {
        header: this.$t(
          'shared.modals.errors.updatedBookingAlreadyCancelled.header'
        ) as string,
        body: this.$t(
          'shared.modals.errors.updatedBookingAlreadyCancelled.body'
        ) as string,
        btnText: this.$t('shared.buttons.close') as string,
      }).then(() => this.goToManageBooking());
    },

    onSuccess(userData: User) {
      const partnerTimeZone: Partners['poi']['timezone'] =
        getAppVariable('poi.timezone');
      const isLessOneHour = checkIsLessOneHour(
        this.stringEntryTime,
        partnerTimeZone
      );

      if (isLessOneHour) {
        this.$openModalAfterRedirect(
          {
            header: this.$t(
              'shared.modals.errors.userDetailsUpdateError.title'
            ) as string,
            body: this.$t(
              'shared.modals.errors.userDetailsUpdateError.body'
            ) as string,
            btnText: this.$t('shared.buttons.close') as string,
          },
          this.goToManageBooking
        );
        return;
      }

      if (typeof this.orderID !== 'string') {
        throw new TypeError("Missing the order ID or it's type isn't a string");
      }

      const body = {
        order_reference: this.orderID,
        user: userData,
        update_all_confirmed_items: true,
      };

      this.loading = true;

      updateBookingDetails(body)
        .then(() => {
          this.$router.push({
            path: NAMED_ROUTES.manage_booking.default,
            query: { personalDetailsSuccess: 'true' },
          });
        })
        .catch((error: any) => {
          const errorResponse = JSON.parse(error.message);

          if (
            errorResponse.body.error?.type ===
            HTTP_ERROR_TYPES.BOOKING_ALREADY_CANCELLED
          ) {
            this.showBookingAlreadyCancelledModal();
            return;
          }
          if (this.tryAgainCount < 3) {
            this.tryAgainCount += 1;
            this.$openModal('GlobalModalError', {
              header: this.$t(
                'shared.modals.errors.technicalIssue.header'
              ) as string,
              body: this.$t(
                'shared.modals.errors.technicalIssue.body'
              ) as string,
              btnText: this.$t('shared.buttons.tryAgain') as string,
            });
          } else {
            this.$openModal('GlobalModalError', {
              header: this.$t(
                'shared.modals.errors.technicalIssue.header'
              ) as string,
              body: this.$t(
                'shared.modals.errors.technicalIssue.body'
              ) as string,
              btnText: this.$t('shared.buttons.tryAgainLater') as string,
            }).then(() => this.goToManageBooking());
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    submitForm() {
      (this.$refs['form'] as Vue).$emit('validate');
    },
  },
});
