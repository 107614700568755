
// Vue
import { defineComponent, PropType } from 'vue';

// Helpers
import { PRODUCT_TYPES } from '@white-label-configuration/constants';

// Types
import type { CartItem, Fee } from '@white-label-types/parking-checkout';

// Components
import InfoGuests from './../sub-components/guests-info.vue';
import BundleCardEntry from './bundle-card-entry.vue'
import BundleCardExit from './bundle-card-exit.vue'
import BundleCardFees from './bundle-card-fees.vue'
import { getAppVariable } from '@white-label-helper/get-app-variable';

export default defineComponent({
  name: 'BundleCardItem',

  components: {
    IconShield: () => import('@white-label-icon/icon-shield'),
    InfoGuests,
    BundleCardEntry,
    BundleCardExit,
    BundleCardFees,
  },

  props: {
    bundleItem: {
      type: Object as PropType<CartItem>,
        required: true,
    },
  },

  data() {
    return {
      PRODUCT_TYPES,
    };
  },

  computed: {
    fees(): Fee[] {
      return !getAppVariable('is_fee_inclusive')
        ? this.bundleItem.fees
        : [];
    },
  },
});
