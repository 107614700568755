
// Vue
import { defineComponent, PropType } from 'vue';
import type { StandardPolicy } from '@white-label-types/parking-booking';
import { mapState } from 'pinia';
import { useCartCheckoutStore } from '@white-label-store/cart-checkout';

export default defineComponent({
  name: 'StandardPolicies',

  props: {
    bundleProductsStandardPolicies: {
      type: Array as PropType<StandardPolicy[]>,
      default: function () {
        return [];
      },
    },
    nonBundleProductsStandardPolicies: {
      type: Array as PropType<StandardPolicy[]>,
      default: function () {
        return [];
      },
    },
    isBookingFeeCharged: {
      type: Boolean,
      required: true,
      default: false,
    },
  },

  computed: {
    ...mapState(useCartCheckoutStore, {
      readCartBundle: 'readCartBundle'
    }),
    cancellationPolicyTitle(): string {
      return this.$t('cancellationAndAmendment.cancellationPolicy').toLowerCase();
    },
    amendmentPolicyTitle(): string {
      return this.$t('cancellationAndAmendment.amendmentPolicy').toLowerCase();
    },
    bundleName(): string {
      return this.readCartBundle?.name || '';
    },
  },
});
