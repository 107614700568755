import type { AppURL } from '../types'
import { getFeatureFlag } from '@white-label-plugin/launch-darkly';
import { ACCOUNT_URLS } from './constants';
import { DEDICATED_AUTH0_FLOW } from '@white-label-configuration/constants';
/**
 * Allows basic navigation between white label applications
 *
 * @param {string | null} domain - The domain of the app to switch to.
 * @param {AppURL} appURL - The URL to switch to.
 * @param {string} queryParams - The query parameters to append to the URL.
 */
export function goToApp(
  domain: string | null,
  appURL: AppURL,
  queryParams?: string | Record<string, string>
): void {
  if (!domain) throw new Error('The provided domain must not be defined');
  // Create URL from the provided domain
  const url = new URL(domain);
  const isAccountAppURL = domain.includes('account') || appURL === ACCOUNT_URLS.MANAGE_BOOKING
    || appURL === ACCOUNT_URLS.SEARCH_BOOKING || appURL === ACCOUNT_URLS.LOGIN || appURL === ACCOUNT_URLS.CALLBACK;

  // Prepend '/dedicated' to the domain if in dedicated flow
  if (!isAccountAppURL) {
    // checks if appURL is not an account URL
    // And builds the dedicated url path
    appURL = buildDedicatedPathname(appURL);
  }

  // add the
  url.pathname = appURL;

  if (queryParams) {
    const searchParams = new URLSearchParams(queryParams);
    searchParams.forEach((value, key) => url.searchParams.set(key, value));
  }

  window.location.assign(url.toString());
}

export function buildDedicatedPathname (appURL: AppURL): AppURL {
  // first check if the feature is enabled,
  // and that we are on a dedicated url path
  if ( getFeatureFlag('CUS_2533_DEDICATED_BOOKING_FLOW')
    && window.location.pathname.includes(DEDICATED_AUTH0_FLOW) ) {
    return ( appURL.startsWith('/')
      ? DEDICATED_AUTH0_FLOW + appURL
      : DEDICATED_AUTH0_FLOW + '/' + appURL ) as AppURL;
  } else {
    // continue on non-dedicated flow
    return appURL;
  }
}
