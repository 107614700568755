
import { defineComponent } from 'vue';

// Types
import type { Total } from '@white-label-types/parking-checkout';

import TicketPreloader from '../ticket-preloader/ticket-preloader.vue';
import ReceiptBookingOrders from '../receipt-booking-orders/receipt-booking-orders.vue';
import AdditionalInfoPreloader from '../additional-info-preloader/additional-info-preloader.vue';
import PartnerizeTracking from '../partnerize-tracking/partnerize-tracking.vue';

export default defineComponent({
  name: 'ReceiptBundleItems',

  components: {
    ReceiptBookingOrders,
    TicketPreloader,
    AdditionalInfoPreloader,
    PartnerizeTracking
  },

  props: {
    bundle: {
      type: Object,
      required: true,
    },

    contentLoading: {
      type: Boolean,
    },

    orderTotals: {
      type: Object as PropType<Total>,
      default: () => ({}),
    },
  },

  data() {
    return {
      modifications: {
        hideDirections: true,
        accessInfoFromEntranceInfo: true,
      },
    };
  },

  computed: {
    /**
     * Returns value of featuree flag: ECOM-1770-Travel-Extras-Payment-Summary
     * @returns a boolean.
     */
     displayTravelExtrasSummary(): boolean {
      return this['$launchDarkly'].variation('ECOM-1770-Travel-Extras-Payment-Summary');
    },
  },
});
