
// Vue
import { defineComponent, PropType } from 'vue';
import type { Fee } from '@white-label-types/parking-checkout';

export default defineComponent({
  name: 'BundleCardFees',

  props: {
    fees: {
      type: Array as PropType<Fee[]>,
      default: function () {
        return [];
      },
    },
  },
});
