
// Packages
import { defineComponent } from 'vue';
import { mapState } from 'pinia';

// Helpers
import { getAppVariable } from '@white-label-helper/get-app-variable';
import { getDomainUrl, DOMAINS_NAMES } from '@white-label-helper/switch-app';
import { getFeatureFlag } from '@white-label-plugin/launch-darkly';

// Stores
import { useDiscountCodeStore } from '@white-label-store/discount-code';

// Constants
import {
  NAMED_ROUTES,
  PROGRESS_INDICATOR_PAGES,
  PRODUCT_TYPES,
} from '@white-label-configuration/constants';

// Components
import { useCartCheckoutStore } from '@white-label-store/cart-checkout';

// Components
import ProgressIndicator from '../progress-indicator/progress-indicator.vue';
import Navbar from '../navbar/navbar.vue';

export default defineComponent({
  name: 'CheckoutNavBar',

  components: {
    NavbarCheckout: Navbar,
    ProgressIndicator,
  },

  data() {
    return {
      NAMED_ROUTES,
      crossSellMixedResultsFlagEnabled: false,
    };
  },

  computed: {
    ...mapState(useCartCheckoutStore, {
      readCartItems: 'readCartItems',
      readCartItemsType: 'readCartItemsType',
    }),

    ...mapState(useDiscountCodeStore, {
      discountQuery: 'discountQuery',
    }),

    cartItemsType() {
      return this.readCartItemsType;
    },

    isLounges(): boolean {
      return this.cartItemsType === 'lounges';
    },

    cartItems() {
      return this.readCartItems;
    },

    isCancellationProtectionAvailable(): boolean {
      return this.cartItems?.[0]?.inventory_option?.details
        ?.cancellation_protection?.is_available;
    },

    productTypes() {
      return getAppVariable('product_types_available') ?? [];
    },

    travelExtrasFlowEnabled(): boolean {
      const primaryProductType = getAppVariable('primary_product_type') ?? '';
      return this.crossSellMixedResultsFlagEnabled
        && primaryProductType === PRODUCT_TYPES.PARKING
        && (
          this.productTypes.includes(PRODUCT_TYPES.LOUNGES)
          || this.productTypes.includes(PRODUCT_TYPES.FASTTRACK)
        );
    },

    extrasProductTypeFlowEnabled(): boolean {
      return !this.travelExtrasFlowEnabled && this.productTypes.includes(PRODUCT_TYPES.EXTRAS);
    },

    bookingAppUrls(): { [key: string]: string } {
      const urls = {
        home: `${getDomainUrl(DOMAINS_NAMES.ECOMMERCE)}${NAMED_ROUTES.home}`,
        search: `${getDomainUrl(DOMAINS_NAMES.ECOMMERCE)}${NAMED_ROUTES.search}`,
        select_extras: '',
      }

      if (this.travelExtrasFlowEnabled) {
        urls.select_extras = `${getDomainUrl(DOMAINS_NAMES.ECOMMERCE)}${NAMED_ROUTES.travel_extras}`
      } else if (this.extrasProductTypeFlowEnabled) {
        urls.select_extras = `${getDomainUrl(DOMAINS_NAMES.ECOMMERCE)}${NAMED_ROUTES.select_extras.product}`;
      }

      return urls;
    },

    shouldSkipExtrasStep(): boolean {
      return this.travelExtrasFlowEnabled
        ? (localStorage.getItem('skippedExtrasStep') ?? '') === 'true'
        : this.bookingAppUrls['select_extras'] === '';
    },

    /**
     * Returns boolean based on if the $route.name exists in Indicators list of routes.
     * If in dedicated wl flow then removes 'dedicated-wl-' out from the route name,
     * so we can use normal route naming convention.
     */
    displayProgressIndicator(): boolean {
      const routeName =
        (getFeatureFlag('CUS_2533_DEDICATED_BOOKING_FLOW') && this.$route.name != null)
          ? this.$route.name.replace('dedicated-wl-', '')
          : this.$route.name;

      return (
        this.cartItemsType !== '' &&
        Object.values(PROGRESS_INDICATOR_PAGES).includes(routeName)
      );
    },

    partner_name() {
      return getAppVariable('partner_name');
    },

    logoURL() {
      return getAppVariable('logo');
    },

    logoRedirectURL() {
      return (
        getAppVariable('logo_redirect_url')
        || getDomainUrl(DOMAINS_NAMES.ECOMMERCE)
      );
    },
  },

  mounted() {
    // The value of this flag may change when the LD context is updated.
    // Listen for changes to the flag to ensure reactivity.
    this.crossSellMixedResultsFlagEnabled = getFeatureFlag('EM-369-CROSS-SELL-MIXED-RESULTS', false);
    this.$launchDarkly.on('change:EM-369-CROSS-SELL-MIXED-RESULTS', (value) => {
      this.crossSellMixedResultsFlagEnabled = value;
    });
  },
});
