import type { Context } from '@white-label-types/middleware';

const auth = ({ app, redirect }: Context) => {
  if (!app['$auth'].isAuthenticated) {
    console.log('MAIN MIDDLEWARE');
    redirect('/login');
  }
}

export default auth;
