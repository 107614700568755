
import { defineComponent } from 'vue';

// Components
import ReceiptHeaderDotsLoader from '../receipt-dots-loader/dots-loader-receipt-header.vue';

export default defineComponent({
  components: {
    ReceiptHeaderDotsLoader,
  },
  props: {
    contentPreloaderEnabled: {
      type: Boolean,
      required: true,
    },
  },
});
