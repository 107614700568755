import Vue from 'vue';
import DataDogClient from '@white-label-plugin/datadog.client';


export default function dataDogPlugin ({ $config }) {
  dataDogClientGuard: {
    // Break out of the guard if we're running in Cypress
    if ('Cypress' in window) {
      break dataDogClientGuard;
    }

    // DEVELOPER NOTE:
    // Add other reasons to not load datadog above

    Vue.use(DataDogClient, { serviceName: $config.dataDogServiceName });
  }
}
