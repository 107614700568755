import { defineStore } from 'pinia';

import { CartItem, Bundle } from '@white-label-types/parking-checkout';

type CartCheckoutStoreState = {
  items: Array<CartItem>,
  bundle: Bundle | null,
}

type Product = {
  productId: string,
  isProtected: boolean
}

/**
 * The Cart Checkout Store Definition
 *
 * @returns {StoreDefinition}
 */
export const useCartCheckoutStore = defineStore({
  id: 'cart-checkout',

  actions: {
    async commitCartItems(items: Array<CartItem>) {
      this.items = items
    },

    async commitCartBundle(bundle: Bundle) {
      this.bundle = bundle;
    },

    async commitUpdateCancellationProtection(product: Product) {
      const productToUpdate = this.items.find(item => item.id === product.productId);

      if (productToUpdate) {
        productToUpdate.is_protected = product.isProtected;
      }
    },
  },

  getters: {
    readCartItemsType: (state) => (state.items.length && state.items[0]?.product_code) || '',
    readCartItems: (state) => state.items,
    readCartBundle: (state) => state.bundle,
  },

  state: () => {
    const defaultCartCheckoutState: CartCheckoutStoreState = {
      items: [],
      bundle: null,
    }

    return defaultCartCheckoutState;
  },
});
