import { getAppVariable } from '@white-label-helper/get-app-variable';
import { datadogRum } from '@datadog/browser-rum';

interface WhitelabelHostDomains {
  account?: string;
  checkout?: string;
  ecommerce?: string;
}

/**
 * Returns the cookie domain
 *
 * @returns string
 */
export const getCookieDomain = (): string => {
  const domain: string = getAppVariable('page_links.domain');
  const hostnames: WhitelabelHostDomains = {};
  Object.assign(hostnames, getAppVariable('page_links.whitelabel_host_domains'));

  // Add overrides
  const overrides = [
    { key: 'account', envVar: 'NUXT_ENV_ACCOUNT_APP_HOST_DOMAIN_OVERRIDE' },
    { key: 'checkout', envVar: 'NUXT_ENV_CHECKOUT_APP_HOST_DOMAIN_OVERRIDE' },
    { key: 'ecommerce', envVar: 'NUXT_ENV_ECOMMERCE_APP_HOST_DOMAIN_OVERRIDE' },
  ] as const;
  let overridesActive = false;
  overrides.forEach(override => {
    const value = process.env[override.envVar];
    if (value) {
      overridesActive = true;
      hostnames[override.key] = value;
    }
  });

  // Check for > 0 hostnames before proceeding
  if (!Object.keys(hostnames).length) {
    const error = 'Cannot compute Auth0 cookie domain, app variable page_links.whitelabel_host_domains not defined.';
    datadogRum.addError(error);
    throw new Error(error);
  }

  // Map each hostname into 2D matrix, then reverse
  const hostnamesArray: string[] = Object.values(hostnames);
  const reversedLevels =
    hostnamesArray.map(hostname => {
      const index = hostname.indexOf('.' + domain);

      // Don't strip domain from hostnames if overrides are active
      if (overridesActive) {
        return hostname.split('.').reverse();
      }

      return hostname.substring(0, index).split('.').reverse();
    });

  // Transpose reversed levels matrix 90deg
  const transposed = [];
  for (let i = 0; i < reversedLevels[0].length; i++) {
    transposed.push(reversedLevels.map(row => row[i]));
  }

  // Find index of common level
  const commonLevelIndex = transposed.findIndex((level) => {
    // Check commonality of array items
    return !level.every((item) => item === level[0]);
  });

  // Rebuild common hostname, fallback to ecommerce hostname
  return commonLevelIndex > 0
    ? '.' + reversedLevels[0].slice(0, commonLevelIndex).reverse().join('.')
    : '.' + domain;
}

export default {
  getCookieDomain,
};
