import { APP_HERO_PRODUCTS, HeroProduct } from '@white-label-configuration/constants'
import { getAppVariable } from '@white-label-helper/get-app-variable';

export function getAppHeroProduct(defaultValue: HeroProduct): HeroProduct {
  const heroProduct: string | undefined = getAppVariable<HeroProduct>('primary_product_type');

  if (heroProduct === undefined) {
    throw new ReferenceError('The "primary_product_type" property is missing from app config');
  }

  if (heroProduct === '') {
    throw new ReferenceError('The hero product config variable must be a filled string');
  }

  const heroProductRecognised: boolean = Object.values(APP_HERO_PRODUCTS).includes(heroProduct as HeroProduct)

  if (heroProductRecognised) {
    return heroProduct as HeroProduct
  }

  // Warn and return the default value if not set
  console.warn(`You have not set a default hero product to fall back to`);
  return defaultValue
}
