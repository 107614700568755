import Vue from 'vue';
import Cookie from 'cookie-universal';
import { SerializeOptions as CookieSerializeOptions } from 'cookie';
import isObject from 'lodash/isObject';
import { getCookieDomain } from '@white-label-helper/get-cookie-domain';

interface CookieOptions extends CookieSerializeOptions {
  lifetime?: number;
}

const sharedCookies = Cookie();

if (process.client) {
  Vue.mixin({
    created(this: Vue) {
      const { $initSharedCookiesListener } = this.$options;

      if (!$initSharedCookiesListener) { return; }

      if (typeof $initSharedCookiesListener !== 'function') {
        throw new TypeError("'this.$initSharedCookiesListener' expected to be function");
      }

      // Waiting for mounting "NotificationsContainer" component
      this.$global_eventEmitter.$on('notificationsContainerMounted', () => {
        // Get map of cookies (keys, values, callbacks) from component.
        const initData = $initSharedCookiesListener.call(this);

        if (!isObject(initData)) {
          throw new Error("'initData' expected to be an object");
        }

        Object.entries(initData).forEach(iterateCookieKey);
      });
    },

    methods: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      $getSharedCookies(cookieKey: string): any {
        return sharedCookies.get(cookieKey);
      },

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      $setSharedCookies(key: string, value: any, options: CookieOptions = {}, callback = () => {}): void {
        const lifetime = options ? options.lifetime : undefined;

        if (lifetime) {
          // eslint-disable-next-line no-param-reassign
          delete options.lifetime;
        }

        const newOptions: CookieSerializeOptions = {
          path: '/',
          ...options,
        };

        if (process.env.NODE_ENV === 'production') {
          // if process run on 'AWS' we need to share token in domain
          newOptions.domain = getCookieDomain();
        } else {
          // if process run on 'localhost' we need to share token in sameSite
          newOptions.sameSite = true;
        }

        if (typeof lifetime === 'number') {
          newOptions.maxAge = (new Date().getTime() / 1000) + lifetime;
        }

        sharedCookies.set(key, value, newOptions);
        callback();
      },
    },
  });
}

function iterateCookieValue(expectedCookieVal: string, actualCookiesValue: string, callback: FunctionConstructor) {
  if (expectedCookieVal === actualCookiesValue) {
    // if 'cookieKey' have value 'actualCookiesValue' and
    // 'actualCookiesValue' same with 'expectedCookieVal'
    // only then we will call callback()
    callback();
  }
}

function iterateCookieKey([cookieKey, mapOfCallbacks]: [string, object]) {
  const actualCookiesValue = sharedCookies.get(cookieKey);

  if (!actualCookiesValue) { return; }

  // Clean cookieKey to avoid duplicating call on refresh page
  sharedCookies.remove(cookieKey);

  Object.entries(mapOfCallbacks).forEach(
    ([expectedCookieVal, callback]) => iterateCookieValue(
      expectedCookieVal,
      actualCookiesValue,
      callback,
    ),
  );
}
