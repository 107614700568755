import { httpUser } from '@white-label-configuration/http';
import { AxiosResponseHeaders } from 'axios';

export interface UserDetailsResponse {
  id: string
  email: string
  customer_id: string
  channel_id: number
  first_name: string
  last_name: string
  zipcode: string | null
  license_plate: string
  phone: {
    phone_number: string
    country_code: string
    formatted_number: string
    country_iso_code: string | null
  }
  is_subscribed: boolean
  password_update_at: string
  vehicles: {
    registration_number: string
    make: string
    model: string
    colour: string
  }[] | null
  created_at: Date
  updated_at: Date,
  marketing_channels: {
    sms: boolean,
    email: boolean
  } | null
}

export interface UserPayload {
  email: string;
  channel_id: number
  first_name: string
  last_name: string
  phone: {
    phone_number: string,
    formatted_number: string,
    country_code: string,
    country_iso_code: string,
  },
  zipcode: string,
  vehicles?: {
    registration_number: string
    make: string
    model: string
    colour: string
  }[]
  marketing_channels?: {
    email?: boolean,
    sms?: boolean
  } | null
}

export interface EmailVerifyPayload {
  user_id: string,
  identity: {
    user_id: string,
    provider: string,
  },
}

interface ChangePasswordResponseData {
  ticket: string
}

export interface ChangePasswordResponse {
  data: ChangePasswordResponseData
}

type AxiosResponse<T> = {
  body: {
    data: T
  },
  headers: AxiosResponseHeaders,
  status: number
}

/**
 * Api URL to get/update the customer details and change password
 * @param bearerToken - Bear token for the user
 */
export const userDetails = {
  get: (bearerToken: string)  => httpUser.get('account/users/current', {
    headers: {
      Authorization: bearerToken,
    },
  }) as Promise<AxiosResponse<UserDetailsResponse>>,
  update: (data: UserPayload, bearerToken: string) => httpUser.patch('account/users/details', {
    body: data,
    headers: {
      Authorization: bearerToken,
    },
  }),
  updatePassword: (bearerToken: string) => httpUser.patch('account/users/password', {
    headers: {
      Authorization: bearerToken,
    },
  }) as Promise<AxiosResponse<ChangePasswordResponseData>>
};

export const emailVerification = {
  resendVerification: (data: EmailVerifyPayload) => httpUser.patch('/verification-email/resend', {
    body: data,
    headers: {
      Authorization: '',
    },
  }),
};
