import { getAppVariable } from '@white-label-helper/get-app-variable';

/**
 * Returns the logout redirect URL
 *
 * @returns string
 */
export const getLogoutRedirectUrl = (): string => {
  return (
    process.env.NUXT_ENV_ACCOUNT_APP_HOST_DOMAIN_OVERRIDE ||
    getAppVariable('page_links.whitelabel_host_domains')?.account ||
    getAppVariable('page_links.domain')
  ) + '/login';
}

export default {
  getLogoutRedirectUrl,
}
