import { DEDICATED_AUTH0_FLOW } from "@white-label-configuration/constants";
import { Route } from "vue-router";
import { jwtDecode } from 'jwt-decode';
import { datadogRum } from "@datadog/browser-rum";

export interface DecodedToken {
  permissions?: string[];
}

/**
 * Checks the current route's auth0flow matches with the given routeName for the dedicated wl flow.
 * @param route Vue Router
 * @param routeName (Optional) route name to check auth0 flow against, defaults to DEDICATED_AUTH0_FLOW
 * @returns True or false if current route matches against name
 */
export const isDedicatedFlow = (route : Route, routeName: string = DEDICATED_AUTH0_FLOW): boolean => {
  return (route?.meta?.[0]?.auth0Flow === routeName);
};

export const getUrlParam = (route: Route, param: string): string => {
  return route?.query?.[param]?.toString();
};

/**
 * Decodes a JWT token
 * @param token The JWT token to decode
 * @returns An object with the decoded token or an empty object if decoding fails
 */
export const decodeToken = (token: string): DecodedToken => {
  try {
    return jwtDecode<DecodedToken>(token.replace('Bearer ', ''));
  } catch (error) {
    datadogRum.addError(
      new Error('Auth decodeToken:', { cause: error }),
      {
        file: 'white-label-helper > dedicated-flow > decodeToken()',
        token: token || ''
      }
    );
    return {};
  }
}

/**
 * Returns the permissions property from the decoded token
 * @param token The JWT token to decode
 * @returns An array of permissions or an empty array if decoding fails
 */
export const getUserPermissions = (token: string): string[] => {
  const decodedToken = decodeToken(token);
  return decodedToken?.['permissions'] || [];
}
