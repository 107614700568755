
// Packages
import { defineComponent, PropType } from 'vue';

// Types
import type { CartItem, CancellationProtection } from '@white-label-types/parking-checkout';
import type { CancellationPolicy, AmendmentPolicy, StandardPolicy } from '@white-label-types/parking-booking';

// Helpers
import { formatPrice } from '@white-label-helper/helper-payment';

import RadioButton from '../radio-button/radio-button.vue';
import StandardPolicies from './sub-components/standard-policies.vue';

export default defineComponent({
  name: 'CancellationAndAmendmentPolicy',

  components: {
    RadioButton,
    IconArrow: () => import('@white-label-icon/icon-arrow'),
    IconShield: () => import('@white-label-icon/icon-shield'),
    StandardPolicies,
  },

  props: {
    cancellationPolicies: {
      type: Array as PropType<CancellationPolicy[] | CartItem['inventory_option']['cancellation_policies'][]>,
      default: () => [],
    },
    amendmentPolicies: {
      type: Array as PropType<AmendmentPolicy[] | CartItem['inventory_option']['amendment_policies'][]>,
      default: () => [],
    },
    isProtected: {
      type: Boolean,
      required: true,
    },
    cancellationProtection: {
      type: Array as PropType<CancellationProtection[]>,
      required: true,
    },
    isBookingFeeCharged: {
      type: Boolean,
      required: true,
      default: false,
    },
  },

  data() {
    return {
      standardPolicyExpanded: false,
      upgradedProtectionExpanded: true,
      selectedOption: 'standardOption',
    };
  },

  computed: {
    upgradedProtectionLabel() {
      return `
        ${this.$t('cancellationAndAmendment.upgradedPolicy')}
        ${formatPrice(this.cancellationProtectionTotal)}
      `;
    },
    // TO DO - Remove FF check when 'ECOM-2801_BUNDLES-PRE-PURCHASE' feature flag is no longer needed
    isCancellationProtectionAvailable(): boolean {
      if (this.$launchDarkly.variation('ECOM-2801_BUNDLES-PRE-PURCHASE')) {
        return this.cancellationProtection.filter(item => item.is_available).every(item => !item.is_bundle_product);
      }
      return this.cancellationProtection.some(item => item.is_available);
    },
    cancellationProtectionTotal(): number {
      return this.cancellationProtection.reduce((acc, item) => acc + (item.totals?.total ?? 0), 0);
    },
    /**
     * Returns the standard cancellation and amendment policies of all products in cart.
     * @returns an array of StandardPolicy objects.
     */
    allProductsStandardPolicies(): StandardPolicy[] {
      return this.cancellationPolicies.map((cancellationPolicyItem: CancellationPolicy) => {
        const amendmentPolicyItem = this.amendmentPolicies.find((item: AmendmentPolicy) => item.product_id === cancellationPolicyItem.product_id);
        return {
          ...cancellationPolicyItem,
          amendment_policy: amendmentPolicyItem ? amendmentPolicyItem.amendment_policy : '',
        } as StandardPolicy;
      });
    },
    /**
     * Returns the standard cancellation and amendment policies of all cart items that are part of a bundle.
     * @returns an array of StandardPolicy objects.
     */
    bundleProductsStandardPolicies(): StandardPolicy[] {
      return this.allProductsStandardPolicies.filter((item: StandardPolicy) => item.is_bundle_product);
    },
    /**
     * Returns the standard cancellation and amendment policies of all cart items that are not part of a bundle.
     * @returns an array of StandardPolicy objects.
     */
    nonBundleProductsStandardPolicies(): StandardPolicy[] {
      return this.allProductsStandardPolicies.filter((item: StandardPolicy) => !item.is_bundle_product );
    },
    /**
     * TO DO - Remove 'cancellationPolicyTitle' and 'amendmentPolicyTitle' when 'ECOM-2801_BUNDLES-PRE-PURCHASE' feature flag is no longer needed
     * These have been moved to another component
     */
    cancellationPolicyTitle(): string {
      return this.$t('cancellationAndAmendment.cancellationPolicy').toLowerCase();
    },
    amendmentPolicyTitle(): string {
      return this.$t('cancellationAndAmendment.amendmentPolicy').toLowerCase();
    },
  },

  mounted() {
    if (this.isProtected) {
      this.selectedOption = 'upgradedOption';
    }
  },

  methods: {
    onRadioSelect(value: string): void {
      this.selectedOption = value;

      if (value === 'standardOption') {
        this.$emit('remove-cancellation-protection');
      } else {
        this.$emit('add-cancellation-protection');
      }
    },
  },
});
