
// Packages
import { defineComponent } from 'vue';

// Types
import type VueI18n from 'vue-i18n/types';
import type { MetaInfo } from 'vue-meta';

// Helpers
import { getAppVariable } from '@white-label-helper/get-app-variable';
import { getFeatureFlag } from '@white-label-plugin/launch-darkly';

// Constants
import { GTM_URLS_BY_ID, NAMED_ROUTES } from '@white-label-configuration/constants';

export default defineComponent({
  name: 'NuxtError',

  components: {
    ErrorPage: () => import('@white-label-frontend/error'),
  },

  props: {
    error: {
      type: Object,
      default: () => ({
        statusCode: 400,
      }),
    },
  },

  head(): MetaInfo {
    const link = []

    if (getFeatureFlag('AP_682_ENABLE_CUSTOM_CSS', false)) {
      link.push({
        rel: 'stylesheet',
        href: `${process.env.NUXT_ENV_PARTNER_CLOUDFRONT_URL}/stylesheets/${process.env.NUXT_ENV_AFFILIATE_APPLICATION_TOKEN}.${process.env.NUXT_ENV_ENVIRONMENT}.css`
      })
    }

    return {
      title: this.createTabTitle,
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      script: [
        {
          json: this.$partnerConfig as { [key: string]: any },
          type: 'application/json',
          vmid: 'partner-channel-config',
        },
      ],
      style: [
        {
          cssText: `
            :root {
              --color-brand-100: ${this.$theme.brand[100]};
              --color-brand-16: ${this.$theme.brand[16]};
              --color-brand-24: ${this.$theme.brand[24]};
              --color-brand-50: ${this.$theme.brand[50]};
              --color-brand-8: ${this.$theme.brand[8]};
              --color-cta-background: ${this.$theme.cta.background};
              --color-cta-font: ${this.$theme.cta.font};
              --color-footer-background: ${this.$theme.footer.background};
              --color-header-background: ${this.$theme.header.background};
              --color-header-progress-circles: ${this.$theme.header['progress-circles']};
            }

            :root {
              --font-family: ${this.$theme.fontFamily};
            }
          `,
        }
      ],
      link,
    };
  },

  computed: {
    getRouteName(): number {
      return this.error.statusCode;
    },

    getPageName(): VueI18n.TranslateResult {
      return this.$t(`pages.titles.${this.getRouteName}`);
    },

    createTabTitle(): string {
      return `${this.getPageName} | ${getAppVariable('partner_name')}`;
    },
  },

  created() {
    this.mountedCheckerHelper();
  },

  mounted() {
    const gtmId = this.$partnerConfig ? this.$partnerConfig.gtm_id as string : process.env.NUXT_ENV_GTM_ID as string;
    const scriptURL = GTM_URLS_BY_ID[gtmId];
    this.$bootstrapGTM({ id: gtmId, scriptURL });
  },

  methods: {
    goBack(): void {
      this.$router.push(NAMED_ROUTES.home);
    },
  },
});
