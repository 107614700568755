
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'BTicketWrap',

  props: {
    isBundleProduct: {
      type: Boolean,
      default: false,
    },
  },
});
