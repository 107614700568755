export {
  required,
  email,
  sameAs,
  minLength,
  maxLength,
  minValue,
  maxValue,
  alphaNum,
  requiredIf,
} from 'vuelidate/lib/validators';

export const onlyCharOrNumber = (value: string): boolean => {
  const regex = /^[A-Za-z0-9]*$/;
  return regex.test(value);
};

export const onlyNumber = (value: string): boolean => {
  const regex = /^\d*$/;
  return regex.test(value);
};

export const notNumber = (value: string): boolean => {
  const regex = /^\D*$/;
  return regex.test(value);
};

export const alphaNumSpace = (value: string): boolean => {
  const regex = /^[\w\-\s]+$/;
  return regex.test(value);
};

export const alphaNumSpaceOrEmpty = (value: string): boolean => {
  const regex = /^$|^[\w\-\s]+$/;
  return regex.test(value);
};

export const isOptional = (callback: (arg0: string) => void, value: string): void | boolean => {
  if (value.length && typeof callback === 'function') {
    return callback.call(this, value);
  }
  return true;
};
