import { defineStore } from 'pinia';

import { TranslateResult } from 'vue-i18n';

export type ModalData = {
  header: TranslateResult;
  body: TranslateResult;
  btnText: TranslateResult;
}

export type ModalDataStoreState = {
  modalContent: ModalData;
  hasData: boolean;
  timestamp: number;
}

/**
 * Modal Data Store Definitions
 *
 * @returns {StoreDefinition}
 */
export const useModalDataStore = defineStore({
  id: 'modalData',

  actions: {
    async commitModalData(modalData: ModalData) {
      this.modalContent.header = modalData.header;
      this.modalContent.body = modalData.body;
      this.modalContent.btnText = modalData.btnText;
      this.hasData = true;
      this.timestamp = Date.now()
    },

    async commitBlankModalData() {
      this.modalContent.header = '';
      this.modalContent.body = '';
      this.modalContent.btnText = '';
      this.hasData = false;
      this.timestamp = Infinity;
    }
  },

  getters: {
    readModalContent: (state) => state.hasData ? state.modalContent : null,
    readTimestamp: (state) => state.timestamp
  },

  state: () => {
    const defaultModalDataState: ModalDataStoreState = {
      modalContent: {
        header: '',
        body: '',
        btnText: '',
      },
      hasData: false,
      timestamp: Infinity
    }

    return defaultModalDataState;
  }
});
