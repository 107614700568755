
// Packages
import { defineComponent } from 'vue';

// Constants
import {
  PROGRESS_INDICATOR_PAGES,
  MY_BOOKINGS_REDIRECTION_PAGES,
  PRODUCT_TYPES,
  APP_HERO_PRODUCTS,
  ProductType,
} from '@white-label-configuration/constants';

// Helpers
import {
  getDomainUrl,
  DOMAINS_NAMES,
  ACCOUNT_URLS,
} from '@white-label-helper/switch-app';
import { getAppVariable } from '@white-label-helper/get-app-variable';
import { getAppHeroProduct } from '@white-label-helper/get-app-hero-product';
import { isBookingPortal } from '@white-label-helper/is-booking-portal';
import { getFeatureFlag } from '@white-label-plugin/launch-darkly';

// Stores
import { mapState } from 'pinia';
import { useDeepLink } from '@white-label-store/deep-link';
import { useSearchResultsStore } from '@white-label-store/search-results';

// Types
import type { TabData } from '@white-label-types/navbar';

// Components
import Navbar from '../navbar/navbar.vue';
import ProgressIndicator from '../progress-indicator/progress-indicator.vue';

type RedirectionPagesKeys = keyof typeof MY_BOOKINGS_REDIRECTION_PAGES;
type RedirectionPagesValues =
  (typeof MY_BOOKINGS_REDIRECTION_PAGES)[RedirectionPagesKeys];

export default defineComponent({
  name: 'EcommerceNavbar',

  components: {
    NavbarParkings: Navbar,
    ProgressIndicator,
  },

  data() {
    return {
      PROGRESS_INDICATOR_PAGES,
      isParkingProduct: getAppHeroProduct('lounges') === 'parking',
      crossSellMixedResultsFlagEnabled: false,
    };
  },

  computed: {
    ...mapState(useDeepLink, ['discount']),
    ...mapState(useSearchResultsStore, [
      'inventoryMetadata',
      'hasExtrasSearchResults'
    ]),

    /**
     * Returns boolean based on if the $route.name exists in Indicators list of routes.
     * If in dedicated wl flow then removes 'dedicated-wl-' out from the route name,
     * so we can use normal route naming convention.
     */
    displayProgress(): boolean {
      const routeName =
        (getFeatureFlag('CUS_2533_DEDICATED_BOOKING_FLOW') && this.$route.name != null)
          ? this.$route.name.replace('dedicated-wl-', '')
          : this.$route.name;

      const result = Object.values(this.pages).includes(
        routeName as RedirectionPagesValues
      );

      return result
    },

    pages(): typeof PROGRESS_INDICATOR_PAGES {
      return PROGRESS_INDICATOR_PAGES;
    },

    productTypes() {
      return getAppVariable<ProductType[]>('product_types_available', []);
    },

    travelExtrasEnabled(): boolean {
      return (
        getAppHeroProduct(APP_HERO_PRODUCTS.PARKING) === APP_HERO_PRODUCTS.PARKING
        && (
          this.productTypes.includes(PRODUCT_TYPES.LOUNGES)
          || this.productTypes.includes(PRODUCT_TYPES.FASTTRACK)
        )
      );
    },

    extrasPage(): string {
      const extrasProductsAvailable = this.productTypes.includes(PRODUCT_TYPES.EXTRAS);
      let result = '';
      if (this.travelExtrasEnabled) {
        result = PROGRESS_INDICATOR_PAGES.travel_extras;
      } else if (extrasProductsAvailable) {
        result = PROGRESS_INDICATOR_PAGES.extras_product;
      }
      return result;
    },

    shouldSkipExtrasStep(): boolean {
      const extrasProductFlowEnabled = this.productTypes.includes(PRODUCT_TYPES.EXTRAS);
      const currentRouteIsExtrasPage = this.$route.name === this.extrasPage;

      // If no extras flow is enabled, skip
      if (!(this.travelExtrasEnabled || extrasProductFlowEnabled)) {
        return true;
      // If we are on an extras page, don't skip
      } else if (currentRouteIsExtrasPage) {
        return false;

      // If travel extras is enabled:
      // - skip if content has finished loading
      // - AND we are on the search page
      // - AND the feature flag is disabled OR the feature flag is enabled but we don't have extras to display
      } else if (this.travelExtrasEnabled) {
        return !this.$contentPreloaderEnabled
          && this.$route.name === PROGRESS_INDICATOR_PAGES.choose
          && (!this.crossSellMixedResultsFlagEnabled || !this.hasExtrasSearchResults)

      // If the extras product flow is enabled, don't skip
      } else if (extrasProductFlowEnabled) {
        return false;
      }

      // Otherwise, skip
      return true;
    },

    tabData(): TabData {
      const domainUrl = getDomainUrl(DOMAINS_NAMES.ACCOUNT);
      const bookingLink = domainUrl ? domainUrl.concat(ACCOUNT_URLS.HOME) : '#';

      if (this.isParkingProduct && isBookingPortal) {
        return {};
      }
      return {
        tabsLinks: {
          bookingLink: bookingLink,
        },
      };
    },

    partner_name() {
      return getAppVariable('partner_name');
    },

    discountQuery() {
      return (
        this.discount && { discount: this.discount }
      );
    },

    logoURL() {
      return getAppVariable('logo');
    },

    logoRedirectURL() {
      return (
        getAppVariable('logo_redirect_url')
        || getDomainUrl(DOMAINS_NAMES.ECOMMERCE)
      );
    },
  },

  mounted() {
    // The value of this flag may change when the LD context is updated.
    // Listen for changes to the flag to ensure reactivity.
    this.crossSellMixedResultsFlagEnabled = getFeatureFlag('EM-369-CROSS-SELL-MIXED-RESULTS', false);
    this.$launchDarkly.on('change:EM-369-CROSS-SELL-MIXED-RESULTS', (value) => {
      this.crossSellMixedResultsFlagEnabled = value;
    });
  },
});
