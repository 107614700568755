// Packages
import { defineStore } from 'pinia';
import { datadogRum } from '@datadog/browser-rum';

// Constants
import { BOOKING_STATUSES } from '@white-label-configuration/constants';

// Types
import type {
  ManageBookingState as State,
  BookingStatus,
} from '@white-label-types/stores';

// Helpers
import { convertTimeObjToString } from '@white-label-helper/time-helpers';
import { createDiscountSummaryProp } from '@white-label-helper/discount-summary-prop-creator';
import {
  getBookingBaggageDetails,
  getBookingItemPiiData,
  getManageBookingState,
} from '@white-label-helper/api-manage-booking';
import { cancelBookingHelper } from '@white-label-helper/pusher-cancel-booking';
import { PiiData } from '@white-label-types/parking-booking';

// Stores
import useDepositStore from '@white-label-store/deposits';

const getManageBookingDefaultState = () => ({
  entryExitInfo: {},
  extras: [],
  groups: [],
  userInfo: {},
  orderData: {
    updated: {
      space: {},
      extras: [],
    },
    original: {
      space: {},
      extras: [],
    },
  },
  isCancellable: false,
  status: '' as BookingStatus,
  discount: null,
  amendmentFee: null,
  language: null,
  productCode: null,
  searchCriteria: null,
  /** The original order number. Only gets populated when product has been modified */
  originalOrderItemId: null,
  items: [],
  token: null,
  piiData: [] as PiiData[],
  bookingBaggageConfig: null,
});

/**
 * Manage-booking Store definition
 *
 *  @returns {StoreDefinition}
 */

export const useManageBookingStore = defineStore({
  id: 'manage-booking',
  getters: {
    readBooking(state) {
      return {
        ...state.manageBookingState,
        entryDateTime: this.readStringEntryTime,
        exitDateTime: this.readStringExitTime,
        cancellationPolicies: state.manageBookingState?.cancellationPolicies,
        amendmentPolicies: state.manageBookingState?.amendmentPolicies,
        amendmentFee: state.manageBookingState?.amendmentFee,
      };
    },
    readBundle: (state: State) => state.manageBookingState?.bundle,
    readCancellationPolicies: (state: State) => state.manageBookingState?.cancellationPolicies,
    readAmendmentPolicies: (state: State) => state.manageBookingState?.amendmentPolicies,
    readId: (state: State) => state.manageBookingState?.id,
    readAmendItemId: (state: State) => state.manageBookingState?.amendItemId,
    readPiiToken: (state: State) => state.manageBookingState?.piiToken,
    readPiiData: (state: State) => state.manageBookingState?.piiData,
    readBookingBaggageConfig: (state: State) => state.manageBookingState?.bookingBaggageConfig,
    readUserInfo: (state: State) => state.manageBookingState?.user,
    readUserEmail: (state: State) => state.manageBookingState?.user?.email,
    readEntryExitInfo: (state: State) => state.manageBookingState?.entryExitInfo,
    readOrderId: (state: State) => state.manageBookingState?.orderId,
    readOrderNumberId: (state: State) => state.manageBookingState?.orderNumberId,
    readOrderItemReference: (state: State) =>
      state.manageBookingState?.orderItemReference,
    readCancellationProtection: (state: State) =>
      state.manageBookingState?.cancellationProtection,
    readManageBookingToken: (state: State) => state.manageBookingState?.token,
    readManageBookingItems: (state: State) => state.manageBookingState?.items,
    readAllExtras(state: State) {
      // Having to do this as it looks like the type changes at some point from an Array to an Object
      const { extras } = state.manageBookingState.extras as { extras: unknown };

      return extras;
    },
    readInventoryName: (state: State) =>
      state.manageBookingState?.inventoryItem?.display_name,
    readInventoryItem: (state: State) => state.manageBookingState?.inventoryItem,
    readAirportName: (state: State) =>
      state.manageBookingState?.locationInfo?.name,
    readOrderTotals: (state: State) => state.manageBookingState?.totals,
    readAmendItem: (state: State) => {
      if ('amendItemId' in state.manageBookingState) {
        const { amendItemId } = state.manageBookingState;
        return state.manageBookingState.items.find(
          (item) => item.id === Number(amendItemId)
        );
      }

      return;
    },
    readEntryTime: (state: State) => {
      if ('entryExitInfo' in state.manageBookingState) {
        const { entryDate, entryTime } = state.manageBookingState.entryExitInfo;
        return {
          date: entryDate,
          time: entryTime,
        };
      }

      return {};
    },
    readExitTime: (state: State) => {
      if ('entryExitInfo' in state.manageBookingState) {
        const { exitDate, exitTime } = state.manageBookingState.entryExitInfo;
        return {
          date: exitDate,
          time: exitTime,
        };
      }

      return {};
    },
    readGroups: (state: State) => state.manageBookingState?.groups,
    readStringEntryTime() {
      return convertTimeObjToString(this.readEntryTime);
    },
    readStringExitTime() {
      return convertTimeObjToString(this.readExitTime);
    },
    readBookingStatus: (state: State) => {
      const { status } = state.manageBookingState;
      return BOOKING_STATUSES[status];
    },
    readDiscount: (state: State) => createDiscountSummaryProp(state.manageBookingState),
    readIsCancelled(){
      return this.readBookingStatus === BOOKING_STATUSES.CANCELLED;
    },
    readIsConfirmed() {
      return this.readBookingStatus === BOOKING_STATUSES.CONFIRMED
    },
    readIsInProgress () {
     return this.readBookingStatus === BOOKING_STATUSES.IN_PROGRESS
    },
    readIsPastBooking () {
     return this.readBookingStatus === BOOKING_STATUSES.PAST_BOOKING
    },
    readIsAmendable: (state: State) => state.manageBookingState?.isAmendable,
    readIsCancellable: (state: State) => state.manageBookingState?.isCancellable,
    readAmendmentFee: (state: State) => state.manageBookingState.amendmentFee,
    readLanguageISOCode: (state: State) => state.manageBookingState.language,
    readProductCode: (state: State) => state.manageBookingState?.productCode,

    /** A list of fields to show on the update details page */
    readPersonalDetailFields: (state: State) => {
      const { licensePlate } = state.showFields;
      return {
        licensePlate,
      };
    },
    readAvailableAdditinalFields: (state: State) => {
      const { inventoryItem } = state.manageBookingState;
      const fields = inventoryItem?.additional_fields;


      return {
        zipcode: {
          toShow: fields?.zipcode?.is_show || false,
          isRequired: fields?.zipcode?.is_required || false,
        },
        phone_number: {
          toShow: fields?.phone_number?.is_show || false,
          isRequired: fields?.phone_number?.is_required || false,
        },
        plate_number: {
          toShow: fields?.plate_number?.is_show || false,
          isRequired: fields?.plate_number?.is_required || false,
        },
        vehicle_make:{
          toShow: fields?.vehicle_make?.is_show || false,
          isRequired: fields?.vehicle_make?.is_required || false,
        },
       vehicle_model:{
          toShow: fields?.vehicle_model?.is_show || false,
          isRequired: fields?.vehicle_model?.is_required || false,
        },
       vehicle_colour:{
          toShow: fields?.vehicle_colour?.is_show || false,
          isRequired: fields?.vehicle_colour?.is_required || false,
        },
        inbound_flight_number: {
          toShow: fields?.inbound_flight_number?.is_show || false,
          isRequired: fields?.inbound_flight_number?.is_required || false,
        },
        outbound_flight_number: {
          toShow: fields?.outbound_flight_number?.is_show || false,
          isRequired: fields?.outbound_flight_number?.is_required || false,
        },
      };
    },
  },
  actions: {
    async dispatchState(
      params: { token: string; amendItemId?: string | null }
    ) {
      try {
        const state = await getManageBookingState(params.token);
        if (params.amendItemId) {
          state.amendItemId = params.amendItemId;
        }
        if (state) {
          this.fieldDisplay({
            licensePlate: state?.showLicensePlate,
          })
          this.commitManageBookingState(state)
          const deposit = useDepositStore();
          deposit.updateCartDepositTotal({
              ...state.payable,
            });
          deposit.updateItemDeposit(state.items);
          return state;
        }

        return null;
      } catch (e) {
        const errorMessage = new Error(
          'Something when wrong setting manage booking state',
          { cause: e }
        );
        datadogRum.addError(errorMessage, {
          partner: window.location.host,
        });

        return errorMessage;
      }
    },

    async dispatchPiiData(
      requestData: { token: string }
    ) {
      const piiData = [];
      try {
        const bookingItems = this.readManageBookingItems;
        for (const bookingItem of bookingItems) {
          const response = await getBookingItemPiiData(
            requestData.token,
            bookingItem.id
          );

          piiData.push({ ...response, bookingItemId: bookingItem.id });
        }
        this.commitPiiData(piiData)

        return null;
      } catch (e) {
        const errorMessage = new Error(
          'Something when wrong setting manage booking state',
          { cause: e }
        );
        datadogRum.addError(errorMessage, {
          partner: window.location.host,
        });

        return e;
      }
    },

    async dispatchBaggageConfigData(
      requestData: {
        token: string;
        manageBookingToken: string;
        bookingId: string;
      }
    ) {
      try {
        this.clearBookingBaggageConfig()
        const response = await getBookingBaggageDetails(
          requestData.token,
          requestData.manageBookingToken,
          requestData.bookingId
        );
        this.setBookingBaggageConfig(response)
        return null;
      } catch (e) {
        return e;
      }
    },

    async dispatchCancelBooking(
      payload: {
        manageBookingToken: string;
        orderReference: string;
        isMultiItem?: boolean;
      }
    ) {
      try {
        const response = await cancelBookingHelper().cancelBooking(
          payload.manageBookingToken,
          payload.orderReference
        );

        let managementToken;
        if (payload.isMultiItem) {
          const responseArr = response as unknown as Array<{
            management_token: { token: string; expiration: number };
            order_reference: string;
          }>;
          managementToken = responseArr[responseArr.length - 1]?.management_token;
        } else {
          const responseObj = response as {
            management_token: { token: string; expiration: number };
            order_reference: string;
          };
          managementToken = responseObj?.management_token;
        }
        this.setManageBookingToken(managementToken)
        this.commitCancelBooking()

        return response;
      } catch (e) {
        const errorMessage = new Error(
          'Something when wrong with booking cancellation',
          { cause: e }
        );
        datadogRum.addError(errorMessage, {
          partner: window.location.host,
        });

        return e;
      }
    },


    commitEntryExitInfo(
      entryDate: State['manageBookingState']['entryExitInfo']
    ) {
      this.manageBookingState = {
        ...this.manageBookingState,
        entryExitInfo: entryDate,
      };
    },
    commitManageBookingState(payload: State['manageBookingState']) {
      this.manageBookingState = { ...this.manageBookingState, ...payload };
    },
    commitClearManageBookingState() {
      this.manageBookingState = getManageBookingDefaultState();
    },
    commitManageBookingExtras(
      payload: State['manageBookingState']['extras']
    ) {
      this.manageBookingState.extras = payload;
    },
    commitCancelBooking() {
      this.manageBookingState.status = BOOKING_STATUSES.CANCELLED;
    },
    setManageBookingToken(
      payload: State['manageBookingState']['token']
    ) {
      this.manageBookingState.token = payload;
    },
    commitIsCancellable(payload: boolean) {
      this.manageBookingState.isCancellable = payload;
    },
    commitPiiData(payload: State['manageBookingState']['piiData']) {
      this.manageBookingState.piiData = payload;
    },
    setBookingBaggageConfig(
      payload: State['manageBookingState']['bookingBaggageConfig']
    ) {
      this.manageBookingState.bookingBaggageConfig = payload;
    },
    clearBookingBaggageConfig() {
      this.manageBookingState.bookingBaggageConfig = null;
    },

    /**
     * Update the state as to which fields to use on the update details page
     */
    fieldDisplay(payload: { licensePlate: boolean }) {
      this.showFields = { ...payload };
    },
    commitUpdateLoungeSearchCriteriaMutation(payload: object) {
      this.manageBookingState.searchCriteria = {
        lounges: {
          ...this.manageBookingState.searchCriteria?.lounges,
          ...payload,
        },
      };
    },
  },
  state: (): State => ({
    manageBookingState: getManageBookingDefaultState(),
    showFields: {
      licensePlate: true,
    },
  }),
  persist: true
})
