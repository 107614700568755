
import { defineComponent, PropType } from 'vue';
import RadioButton from '../radio-button/radio-button.vue';
import type { RadioGroupOption } from './types';

export default defineComponent({
  name: 'RadioButtonGroup',

  components: {
    RadioButton,
  },

  props: {
    options: {
      type: Array as PropType<RadioGroupOption[]>,
      default: () => [],
    },
    name: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    optionClass: {
      type: String,
      default: 'rounded-md cursor-pointer transition-all duration-300 px-3 py-2.5 mb-2',
    },
    selectedStyle: {
      type: String,
      default: 'border border-brand-100',
    },
    unselectedStyle: {
      type: String,
      default: 'border border-mag-elements-dividers hover:border-brand-50',
    },
    showDiscount: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      initial: this.value,
    };
  },

  methods: {
    onInput (option: string) {
      this.$emit('input', option);
    },
  },
});
