
import { defineComponent } from 'vue';
import { formatPrice } from '@white-label-helper/helper-payment';
import { StatusCodes } from 'http-status-codes';
import { getAppVariable } from '@white-label-helper/get-app-variable';

import {
  NAMED_ROUTES,
  ROUTE_NAMES
} from '@white-label-configuration/constants';

import type { Receipt, CartItem } from '@white-label-types/parking-checkout';
type SummaryElementItems = CartItem[] | Receipt['items'];
type SummaryElementItem = SummaryElementItems extends (infer U)[] ? U : never;

// Stores
import { mapState, mapActions } from 'pinia';
import { useCartStore } from '@white-label-store/cart';
import { useMultiBasketStore } from '@white-label-store/multi-basket';

import { TranslateResult } from 'vue-i18n';

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'NavbarBasket',
  components: {
    IconBasket: () => import('@white-label-icon/icon-basket'),
  },
  data() {
    return {
      tryAgainCount: 0,
    };
  },
  computed: {
    ...mapState(useCartStore, {
      readCartItems: 'readCartItems',
      readCartUpdated: 'readCartUpdated',
      readTotals: 'readTotals',
      readToken: 'readToken',
    }),
    showBasketDot() {
      return this.readCartUpdated;
    },
    showBasket(): boolean {
      if (!this['isMultiBasket']) return false
      const currentRouteName = this.$route.name;
      if (currentRouteName!== ROUTE_NAMES.home && currentRouteName!== ROUTE_NAMES.search ) return false
      if (currentRouteName === ROUTE_NAMES.search) return true;
      return this.hasBasketItems
    },
    cartItems() {
      return this.readCartItems;
    },
    hasBasketItems(): boolean {
      return this.cartItems?.length > 0;
    },

    total(): string | Intl.NumberFormat {
      const total = Number(this.readTotals?.total)
      return formatPrice(total);
    },
    cartToken() {
      return this.readToken;
    },
    isMultiBasket(): boolean {
      return !!getAppVariable('is_multi_basket');
    },
  },
  watch: {
    $route(to) {
      this.onRouteUpdate(to.name)
    }
  },
  mounted() {
    this.onRouteUpdate(this.$route.name as string)
  },
  methods: {
    ...mapActions(useCartStore, {
      commitCartUpdated: 'commitCartUpdated',
      commitClearCart: 'commitClearCart',
      commitCartLoading: 'commitCartLoading',
      dispatchFetchCart: 'dispatchFetchCart',
    }),
    ...mapActions(useMultiBasketStore, {
      commitShowBasketSlideOver: 'commitShowBasketSlideOver',
    }),
    onClickHandler(): void {
      this.commitShowBasketSlideOver(true);
      this.commitCartUpdated(false);
    },
    showToGoHomeError(header?: TranslateResult, body?: TranslateResult) {
      if (header === null || header === undefined) {
        header = this.$t('UI.modalError.sessionExpiredHeader');
      }
      if (body === null || body === undefined) {
        body = this.$t('UI.modalError.sessionExpiredError');
      }
      this.commitClearCart()
      this.$openModal('GlobalModalError', {
        header: header,
        body: body,
        btnText: this.$t('shared.buttons.goHome'),
        btnType: 'custom',
      }).then(() => {
        window.location.assign(NAMED_ROUTES.home);
      })
    },
    showRetryErrorModal(currentRouteName: string): void {
      this.$openModal('GlobalModalError', {
        header: this.$t('shared.modals.errors.technicalIssue.header'),
        body: this.$t('shared.modals.errors.technicalIssue.body'),
        btnText: this.$t('shared.buttons.tryAgain'),
        btnType: 'custom',
      }).then(() => {
        if (this.tryAgainCount <= 3) {
          // eslint-disable-next-line no-plusplus
          this.tryAgainCount += 1;
          this.onRouteUpdate(currentRouteName);
        } else {
          this.showToGoHomeError(
            this.$t('UI.modalError.header'),
            this.$t('UI.modalError.technicalIssueBody')
          );
        }
      })
    },
    showTechnicalIssueModal(): void {
      this.$openModal('GlobalModalError', {
        header: this.$t('UI.modalError.addingProductTryLaterTechError'),
        body: this.$t('UI.modalError.addingProductTryLaterTechError'),
        btnText: this.$t('shared.buttons.close'),
      });
    },
    onRouteUpdate(currentRouteName: string): void {
      if (!this['isMultiBasket']) return
      if (!this.cartToken) return
      if (currentRouteName !== ROUTE_NAMES.home && currentRouteName !== ROUTE_NAMES.search ) return

      this.dispatchFetchCart()
        .then(() => this.tryAgainCount = 0)
        .catch((err: Error) => {
          const parseError = JSON.parse(err.message);
          const isExpiredOrNotFound = [
            StatusCodes.NOT_FOUND,
            StatusCodes.GONE
          ].includes(parseError.status ?? StatusCodes.INTERNAL_SERVER_ERROR);

          if (currentRouteName === ROUTE_NAMES.home) {
            this.commitClearCart()
            if (!isExpiredOrNotFound) {
              this.showTechnicalIssueModal();
            }
            return;
          }

          if (!isExpiredOrNotFound) {
            this.showRetryErrorModal(currentRouteName);
            return;
          }

          this.commitClearCart();

          if (parseError.status === StatusCodes.GONE) {
            this.showToGoHomeError();
          }

          this.commitCartLoading(false);
        })
    }
  },
});
