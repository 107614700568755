
// Vue
import { defineComponent, PropType } from 'vue';

// Helpers
import { formatPrice } from '@white-label-helper/helper-payment';
import { format, formatWithLocale, DATE_TIME_FORMATS } from '@white-label-helper/date-utilities';
import { datadogRum } from '@datadog/browser-rum';
import { StatusCodes } from 'http-status-codes';
import { TranslateResult } from 'vue-i18n';
import { NAMED_ROUTES, BOOKING_STATUSES, PRODUCT_TYPES } from '@white-label-configuration/constants';
import { getAppVariable } from '@white-label-helper/get-app-variable';
import { gaDataLayerLounges } from '@white-label-helper/ga-data-layer';
import { gaEventLounges, refreshDataLayer } from '@white-label-helper/ga-tracking';

// Types
import type { GenerateProduct } from '@white-label-types/parking-booking';
import type { Fee } from '@white-label-types/parking-checkout';

// Store
import { mapActions } from 'pinia';
import { useCartStore } from '@white-label-store/cart';
import { useSearchResultsStore } from '@white-label-store/search-results';

// Components
import ProductSummaryCardIcon from './product-summary-card-icon.vue';
import ProductSummaryCardDropdown from './product-summary-card-dropdown.vue';
import InfoGuests from './sub-components/guests-info.vue';
import InfoExtras from './sub-components/extras-info.vue';
import InfoFlightTime from './sub-components/flight-time-info.vue';
import InfoPrivateLounge from './sub-components/private-lounge-info.vue';
import ProductTags from '../product-tags/product-tags.vue';


export default defineComponent({
  name: 'ProductSummaryCard',
  components: {
    IconArrow: () => import('@white-label-icon/icon-arrow'),
    IconShield: () => import('@white-label-icon/icon-shield'),
    IconTrash: () => import('@white-label-icon/icon-trash'),
    ProductSummaryCardIcon,
    ProductSummaryCardDropdown,
    InfoGuests,
    ProductTags,
    InfoFlightTime,
    PrivateLoungeInfo: InfoPrivateLounge,
    InfoExtras,
  },
  props: {
    displayLocation: {
      type: String,
      default: 'basket'
    },
    showIcon: {
      type: Boolean,
      default: false
    },
    product: {
      type: Object as PropType<GenerateProduct>,
      required: true
    },
    hasRemoveButton: {
      type: Boolean,
      default: true
    },
    amendingTitle: {
      type: String,
      default: ''
    },
    selectedForCancellation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDisabled: false,
    };
  },
  computed: {
    componentList(): { header: string[]; info: string[] } {
      const components: { header: string[]; info: string[] } = {
        header: [],
        info: []
      };
      if ([PRODUCT_TYPES.LOUNGES, PRODUCT_TYPES.FASTTRACK ].includes(this.productType)) {
        components.header.push('ProductTags');
          components.info.push('InfoGuests');
          if (this.isPrivateLounge) {
            components.info.push('PrivateLoungeInfo');
            components.info.unshift('InfoFlightTime');
          }
      }
      if (this.productType === PRODUCT_TYPES.EXTRAS) {
        components.info.push('InfoExtras');
      }
      return components;
    },
    entryDate() {
      let entry = this.product?.details?.entry_date ?? this.product?.inventory_item?.entry_date;

      if (!entry && this.product?.search_criteria) {
        entry = this.product?.search_criteria[this.productType].date1 + ' ' + this.product?.search_criteria[this.productType].time1;
      }

      return new Date(entry);
    },
    exitDate() {
      let exit = this.product?.details?.exit_date ?? this.product?.inventory_item?.exit_date;

      if (exit) {
        return new Date(exit);
      }

      if (this.productType && this.productType === PRODUCT_TYPES.PARKING && this.product?.search_criteria) {
        return new Date(this.product.search_criteria[this.productType].date2 + ' ' + this.product.search_criteria[this.productType].time2);
      }

      exit = this.entryDate;
      let duration = 15;

      if (this.productType && this.productType === PRODUCT_TYPES.LOUNGES && this.product?.inventory_option?.details) {
        duration = this.product.inventory_option?.details?.duration ?? 0;
      }

      exit.setTime(exit.getTime() + (duration * 1000 * 60));

      return exit;
    },
    entryText(): TranslateResult {
      if (!this.entryExitVisible().entry) {
        return '';
      }

      const entryDate = formatWithLocale(
        this.entryDate,
        (getAppVariable('poi.country.code') === 'US' || getAppVariable('poi.country.code') === 'GB') ?
          DATE_TIME_FORMATS.weekday_month_day_year_am_pm_lower_case : DATE_TIME_FORMATS.weekday_month_day_year_time_24hr_lower_case,
        this.$i18n.locale,
      );
      let exitDate = '';

      if (this.productType === PRODUCT_TYPES.LOUNGES || this.productType === PRODUCT_TYPES.FASTTRACK) {
        exitDate = formatWithLocale(
          this.exitDate,
          (getAppVariable('poi.country.code') === 'US' || getAppVariable('poi.country.code') === 'GB') ? DATE_TIME_FORMATS.weekday_month_day_year_am_pm_lower_case : DATE_TIME_FORMATS.weekday_month_day_year_time_24hr_lower_case,
          this.$i18n.locale,
        );
      }
      return this.$t(`UI.productSummaryCard.info.entry.${this.productType}`, {
        entryDate,
        exitDate
      });
    },
    exitText(): TranslateResult {
      if (!this.entryExitVisible().exit) {
        return '';
      }

      const exitDate = format(
        this.exitDate,
        (getAppVariable('poi.country.code') === 'US' || getAppVariable('poi.country.code') === 'GB') ? DATE_TIME_FORMATS.weekday_month_day_year_am_pm_lower_case : DATE_TIME_FORMATS.weekday_month_day_year_time_24hr_lower_case,
      );

      return this.$t(`UI.productSummaryCard.info.exit.${this.productType}`, {
        exitDate
      });
    },
    iconName(): string {
      if (this.productType === PRODUCT_TYPES.LOUNGES) {
        if (this.isPrivateLounge) {
          return this.privateLoungeFlyingDirection === 'inbound'
            ? 'plane-end'
            : 'plane-takeoff';
        }

        return 'lounges';
      }

      if (this.productType === PRODUCT_TYPES.LOUNGES) {
        return this.productOption === 'security' ? 'fasttrack' : 'invoice';
      }

      return 'parking';
    },
    productType(): string {
      return this.product?.product_code ?? PRODUCT_TYPES.PARKING;
    },
    isPrivateLounge() {
      return this.productOption === 'lounge_private';
    },
    productOption() {
      return this.product.inventory_item?.product_option?.code;
    },
    privateLoungeFlyingDirection() {
      return (
        this.product.details?.direction ?? 'inbound'
      ).toLowerCase();
    },
    fees(): Fee[] {
      return !getAppVariable('is_fee_inclusive')
        ? this.product.fees
        : [];
    },
    hasCancellationProtection() {
      return this.product.cancellation_policies?.is_protected || this.product.is_protected;
    },
    isProductCancelled(): boolean {
      return this.product?.status?.toUpperCase() === BOOKING_STATUSES.CANCELLED;
    },
    showCancellationStatus(): boolean {
      return this.selectedForCancellation || this.isProductCancelled;
    },
  },

  methods: {
    ...mapActions(useCartStore, {
      dispatchRemoveFromCart: 'dispatchRemoveFromCart',
      commitClearCart: 'commitClearCart'
    }),
    ...mapActions(useSearchResultsStore, ['removeCartItemId']),
    formatPrice,
    entryExitVisible() {
      const visibility = {
        entry: true,
        exit: true
      };

      if (this.productType === PRODUCT_TYPES.EXTRAS) {
        return {
          entry: false,
          exit: false
        };
      }

      if (this.productType === PRODUCT_TYPES.LOUNGES || this.productType === PRODUCT_TYPES.FASTTRACK) {
        visibility.exit = false;
        if (this.isPrivateLounge) {
          visibility.entry = false;
        }
      }

      if (this.productType === 'cancellation_protection') {
        visibility.entry = false;
        visibility.exit = false;
      }

      return visibility;
    },
    async removeItem(item: ProductsType) {
      this.isDisabled = true;
      await this.dispatchRemoveFromCart(item.id)
        .then(() => {
          this.removeCartItemId({
            cart_item_id: item.id,
            isTravelExtras: item.product_code === PRODUCT_TYPES.EXTRAS,
          });
        })
        .catch((error) => {
          this.$store.commit('multiBasket/storeShowBasketSlideOver', false);

          const statusCode = JSON.parse(error.message)?.status;
          const unableToRemoveStatusCodes = [
            StatusCodes.INTERNAL_SERVER_ERROR,
            StatusCodes.NOT_FOUND
          ];
          const sessionExpiredStatusCodes = [StatusCodes.GONE];
          datadogRum.addError(error);

          if (unableToRemoveStatusCodes.includes(statusCode)) {
            this.$openModal('GlobalModalError', {
              header: this.$t('UI.modalError.unableToRemoveProductHeader'),
              body: this.$t('UI.modalError.unableToRemoveProductBody'),
              btnText: this.$t('shared.buttons.close')
            });
          }

          if (sessionExpiredStatusCodes.includes(statusCode)) {
            this.$openModal('GlobalModalError', {
              header: this.$t('UI.modalError.sessionExpiredHeader'),
              body: this.$t('UI.modalError.sessionExpiredError'),
              btnText: this.$t('UI.modalError.startNewSearch')
            }).then(() => {
              this.basketExpiredErrorHandler();
            });
          }
        });
        if(this.productType === PRODUCT_TYPES.LOUNGES ){
          this.GTMRefreshDataLayer(item.search_criteria);
          gaEventLounges(this.$gtm, {
            event: 'remove_from_cart',
            items: [{ ...item, index: 0 }],
          });
        }

      },
    GTMRefreshDataLayer(searchCriteria) {
      refreshDataLayer(this.$gtm);
      const extendTrackingInformation = {
        loginStatus: this.$auth.isAuthenticated || false,
        userId: this.$auth.user?.sub || '',
      }
      this.$gtm.push(gaDataLayerLounges(searchCriteria, extendTrackingInformation));
   },
    basketExpiredErrorHandler() {
      // Reset cart
      this.commitClearCart();
      localStorage.setItem('initToken', '');
      localStorage.setItem('vuex', JSON.stringify({ cart: { totals: null } }));
      this.$router.push(NAMED_ROUTES.home);
    }
  }
});
